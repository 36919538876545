import React from "react"
import ReactDOM from "react-dom"

import TodoContainer from "./components/TodoContainer"



function App() {
  return (
    <React.StrictMode>
      <TodoContainer></TodoContainer>
    </React.StrictMode>
  )
   

}

export default App;


